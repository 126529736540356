import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash/fp';
import { Button, Input, Select, Table } from 'antd'; // Уберём DebounceSelect и используем обычный Select
import { round } from 'lodash';
import {
  Wrapper,
  HeaderWrapper,
  InputNumberWrapper,
  CardWrapper,
} from './styled';

const InvestorComponent = ({
                             getInvestorsData,
                             addNewInvestor,
                             // updateInvestorFunds,
                             loading,
                             investors,
                             portfolio,
                             transactionsByPortfolio,
                             transactionsByUser,
                             successMessage,
                             error,
                           }) => {
  const [investorId, setInvestorId] = useState(null);
  const [amount, setAmount] = useState(0);
  // const [isNewLiquidity, setIsNewLiquidity] = useState(true);

  // Загружаем данные инвесторов при монтировании компонента
  React.useEffect(() => {
    getInvestorsData();
  }, [getInvestorsData]);

  const handleAddInvestor = () => {
    if (investorId) {
      addNewInvestor({ isNewLiquidity: false, investorId, amount });
    }
  };
  //
  // const handleUpdateInvestorFunds = () => {
  //   if (investorId && amount > 0) {
  //     updateInvestorFunds({ investorId, portfolioId: portfolio.id, amount });
  //   }
  // };

  const columns = [
    {
      title: 'Инвестор',
      dataIndex: 'login',
      key: 'login',
    },
    {
      title: 'Текущая сумма',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => `$${round(value, 2)}`,
    },
    {
      title: 'Доля',
      dataIndex: 'shares',
      key: 'shares',
      render: (value) => round(value, 1),
    },
    {
      title: 'Процент',
      dataIndex: 'percent',
      key: 'percent',
      render: (value) => `${round(value, 2)}%`,
    },
  ];

  return (
    <Wrapper>
      <HeaderWrapper>
        <h2>Управление Инвесторами</h2>
        {portfolio && (
          <div>
            <p>Общая стоимость портфеля: ${round(portfolio.totalValue, 2)}</p>
            <p>Общее количество долей: {round(portfolio.totalShares, 2)}</p>
          </div>
        )}
        <InputNumberWrapper>
          <Select
            placeholder="Выберите инвестора"
            value={investorId}
            onChange={setInvestorId}
            options={map(investor => ({ value: investor.id, label: investor.login }), investors)}
            loading={loading}
          />
          <Input
            type="number"
            placeholder="Введите сумму"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
          />
          <Button
            type="primary"
            onClick={handleAddInvestor}
            disabled={!investorId || amount === 0}
          >
            Добавить
          </Button>
          {/* <Button */}
          {/*  type="primary" */}
          {/*  onClick={handleUpdateInvestorFunds} */}
          {/*  disabled={!investorId || amount <= 0} */}
          {/* > */}
          {/*  Обновить средства */}
          {/* </Button> */}
        </InputNumberWrapper>
        {/* <Button type="default" onClick={() => setIsNewLiquidity(!isNewLiquidity)}> */}
        {/*  {isNewLiquidity ? 'Добавить новые средства' : 'Передать долю'} */}
        {/* </Button> */}
        {successMessage && <p>{successMessage}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </HeaderWrapper>

      <CardWrapper>
        <h3>Транзакции по портфелю</h3>
        <Table columns={columns} dataSource={transactionsByPortfolio} rowKey="id" loading={loading} />
      </CardWrapper>

      <CardWrapper>
        <h3>Мои Транзакции</h3>
        <Table columns={columns} dataSource={transactionsByUser} rowKey="id" loading={loading} />
      </CardWrapper>
    </Wrapper>
  );
};

InvestorComponent.propTypes = {
  getInvestorsData: PropTypes.func.isRequired,
  addNewInvestor: PropTypes.func.isRequired,
  updateInvestorFunds: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  investors: PropTypes.array.isRequired,
  portfolio: PropTypes.object,
  transactionsByPortfolio: PropTypes.array.isRequired,
  transactionsByUser: PropTypes.array.isRequired,
  successMessage: PropTypes.string,
  error: PropTypes.string,
};

export default InvestorComponent;
