import {
  GET_INVESTORS_DATA_PENDING,
  GET_INVESTORS_DATA_SUCCESS,
  GET_INVESTORS_DATA_FAILURE,
  ADD_NEW_INVESTOR_PENDING,
  ADD_NEW_INVESTOR_SUCCESS,
  ADD_NEW_INVESTOR_FAILURE,
  UPDATE_INVESTOR_FUNDS_PENDING,
  UPDATE_INVESTOR_FUNDS_SUCCESS,
  UPDATE_INVESTOR_FUNDS_FAILURE,
} from '../actions';

// Начальное состояние для работы с инвесторами
const initialState = {
  investors: [], // Данные о потенциальных инвесторах
  portfolio: null, // Данные о портфеле пользователя
  transactionsByPortfolio: [], // Транзакции по портфелю
  transactionsByUser: [], // Транзакции пользователя
  loading: false, // Индикатор загрузки
  error: null, // Ошибка при выполнении запроса
  successMessage: null, // Сообщение об успешной операции
};

// Редьюсер для работы с инвесторами
const investorReducer = (state = initialState, action) => {
  switch (action.type) {
    // Получение данных инвесторов
    case GET_INVESTORS_DATA_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_INVESTORS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        investors: action.payload.potentialInvestors, // Список потенциальных инвесторов
        portfolio: action.payload.portfolio, // Данные портфеля пользователя
        transactionsByPortfolio: action.payload.transactionsByPortfolio, // Транзакции по портфелю
        transactionsByUser: action.payload.transactionsByUser, // Транзакции пользователя
        error: null,
      };

    case GET_INVESTORS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Добавление нового инвестора
    case ADD_NEW_INVESTOR_PENDING:
      return {
        ...state,
        loading: true,
        successMessage: null,
        error: null,
      };

    case ADD_NEW_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        investors: [...state.investors, action.payload], // Добавляем нового инвестора
        successMessage: 'Инвестор успешно добавлен!',
        error: null,
      };

    case ADD_NEW_INVESTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successMessage: null,
      };

    // Обновление средств инвестора
    case UPDATE_INVESTOR_FUNDS_PENDING:
      return {
        ...state,
        loading: true,
        successMessage: null,
        error: null,
      };

    case UPDATE_INVESTOR_FUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        investors: state.investors.map(investor =>
          (investor.id === action.payload.id ? { ...investor, ...action.payload } : investor),
        ), // Обновляем информацию инвестора
        successMessage: 'Средства инвестора успешно обновлены!',
        error: null,
      };

    case UPDATE_INVESTOR_FUNDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        successMessage: null,
      };

    default:
      return state;
  }
};

export default investorReducer;
