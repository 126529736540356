import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  getInvestorsDataFailureAC,
  getInvestorsDataSuccessAC,
  addNewInvestorFailureAC,
  addNewInvestorSuccessAC,
  updateInvestorFundsFailureAC,
  updateInvestorFundsSuccessAC, getInvestorsDataAC,
} from '../actionCreators/investor';
import {
  loadingPendingAC,
  loadingSuccessAC,
} from '../actionCreators/aplication';
import {
  GET_INVESTORS_DATA_PENDING,
  ADD_NEW_INVESTOR_PENDING,
  UPDATE_INVESTOR_FUNDS_PENDING,
} from '../actions';
import { investorAPI } from '../api';

 // Предполагается, что есть `investorAPI` для вызова API
function* getInvestorsData() {
  try {
    yield put(loadingPendingAC());

    // Вызов API для получения данных инвесторов
    const data = yield call(investorAPI.getInvestorsData);
    yield put(getInvestorsDataSuccessAC(data)); // Передача данных в reducer
    yield put(loadingSuccessAC());
  } catch ({ response: { data } }) {
    yield put(getInvestorsDataFailureAC(data)); // Обработка ошибки
    yield put(loadingSuccessAC());
  }
}

function* addNewInvestor({ payload }) {
  try {
    yield put(loadingPendingAC());

    const { isNewLiquidity, investorId, amount } = payload;
    const { data } = yield call(investorAPI.addNewInvestor, { isNewLiquidity, investorId, amount });

    yield put(addNewInvestorSuccessAC(data)); // Успешное добавление инвестора
    yield put(loadingSuccessAC());

    yield put(getInvestorsDataAC());
  } catch ({ response: { data } }) {
    yield put(addNewInvestorFailureAC(data)); // Обработка ошибки
    yield put(loadingSuccessAC());
  }
}

function* updateInvestorFunds({ payload }) {
  try {
    yield put(loadingPendingAC());

    const { investorId, portfolioId, amount } = payload;
    const { data } = yield call(investorAPI.updateInvestorFunds,
      { investorId, portfolioId, amount },
    );

    yield put(updateInvestorFundsSuccessAC(data)); // Успешное обновление средств инвестора
    yield put(loadingSuccessAC());

    yield put(getInvestorsDataAC());
  } catch ({ response: { data } }) {
    yield put(updateInvestorFundsFailureAC(data)); // Обработка ошибки
    yield put(loadingSuccessAC());
  }
}

export function investorSaga() {
  return all([
    takeEvery(GET_INVESTORS_DATA_PENDING, getInvestorsData),
    takeEvery(ADD_NEW_INVESTOR_PENDING, addNewInvestor),
    takeEvery(UPDATE_INVESTOR_FUNDS_PENDING, updateInvestorFunds),
  ]);
}
