import moment from 'moment';

import {
  DELETE_USER_SUCCESS,
  GET_USER_ORDERS_SUCCESS,
  HANDLE_COIN_HOLD_PLACE,
  HANDLE_DATE_RANGE_CHANGE,
  SET_USER_ORDER_SUCCESS,
} from '../actions';

const initialState = {
  list: [],
  originList: [],
  dateRange: [],
  coinHoldPlace: 'All',
};

const order = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_ORDER_SUCCESS:
    case GET_USER_ORDERS_SUCCESS: {
      return { ...state, list: payload, originList: payload };
    }

    case DELETE_USER_SUCCESS: {
      return initialState;
    }

    case HANDLE_COIN_HOLD_PLACE: {
      return {
        ...state,
        coinHoldPlace: payload,
        list: state.originList.filter(
          ({ place }) => payload === 'All' || place === payload,
        ),
      };
    }

    case HANDLE_DATE_RANGE_CHANGE: {
      return {
        ...state,
        dateRange: payload,
        list: state.originList.filter((data) => {
          const dataDate = moment(data.date);
          if (!payload || !payload[0] || !payload[1]) {
            return true;
          }

          return (
            dataDate.isSameOrAfter(payload[0], 'day') &&
            dataDate.isSameOrBefore(payload[1], 'day')
          );
        }),
      };
    }

    default:
      return state;
  }
};

export default order;
