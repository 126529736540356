import { connect } from 'react-redux';
import {
  isLoading,
  getInvestors,
  getPortfolio,
  getTransactionsByPortfolio,
  getTransactionsByUser,
  getError,
  getSuccessMessage,
} from '../selectors/investor';

import {
  getInvestorsDataAC,
  addNewInvestorAC,
  updateInvestorFundsAC,
} from '../actionCreators/investor';
import InvestorComponent from '../components/InvestorComponent';

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  investors: getInvestors(state),
  portfolio: getPortfolio(state),
  transactionsByPortfolio: getTransactionsByPortfolio(state),
  transactionsByUser: getTransactionsByUser(state),
  error: getError(state),
  successMessage: getSuccessMessage(state),
});

const mapDispatchToProps = {
  getInvestorsData: getInvestorsDataAC,
  addNewInvestor: addNewInvestorAC,
  updateInvestorFunds: updateInvestorFundsAC,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorComponent);
