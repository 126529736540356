// Добавление нового инвестора
import {
  ADD_NEW_INVESTOR_FAILURE,
  ADD_NEW_INVESTOR_PENDING,
  ADD_NEW_INVESTOR_SUCCESS,
  GET_INVESTORS_DATA_FAILURE,
  GET_INVESTORS_DATA_PENDING,
  GET_INVESTORS_DATA_SUCCESS,
  UPDATE_INVESTOR_FUNDS_FAILURE,
  UPDATE_INVESTOR_FUNDS_PENDING,
  UPDATE_INVESTOR_FUNDS_SUCCESS,
} from '../actions';

// Начать запрос на получение данных инвесторов
export const getInvestorsDataAC = () => ({
  type: GET_INVESTORS_DATA_PENDING,
});

// Успешное получение данных инвесторов
export const getInvestorsDataSuccessAC = (data) => ({
  type: GET_INVESTORS_DATA_SUCCESS,
  payload: data,
});

// Ошибка при получении данных инвесторов
export const getInvestorsDataFailureAC = (error) => ({
  type: GET_INVESTORS_DATA_FAILURE,
  payload: error,
});

export const addNewInvestorAC = ({ isNewLiquidity, investorId, amount }) => ({
  type: ADD_NEW_INVESTOR_PENDING,
  payload: { isNewLiquidity, investorId, amount },
});

export const addNewInvestorSuccessAC = (data) => ({
  type: ADD_NEW_INVESTOR_SUCCESS,
  payload: data,
});

export const addNewInvestorFailureAC = (error) => ({
  type: ADD_NEW_INVESTOR_FAILURE,
  payload: error,
});

// Обновление средств инвестора
export const updateInvestorFundsAC = ({ investorId, portfolioId, amount }) => ({
  type: UPDATE_INVESTOR_FUNDS_PENDING,
  payload: { investorId, portfolioId, amount },
});

export const updateInvestorFundsSuccessAC = (data) => ({
  type: UPDATE_INVESTOR_FUNDS_SUCCESS,
  payload: data,
});

export const updateInvestorFundsFailureAC = (error) => ({
  type: UPDATE_INVESTOR_FUNDS_FAILURE,
  payload: error,
});
