import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

const localState = get('investor'); // Корневое состояние для инвесторов

// Селектор для загрузки
export const isLoading = createSelector(localState, get('loading'));

// Селектор для получения всех инвесторов
export const getInvestors = createSelector(localState, get('investors'));

// Селектор для получения информации о портфеле пользователя
export const getPortfolio = createSelector(localState, get('portfolio'));

export const getTransactionsByPortfolio = createSelector(
  localState,
  ({ investors, transactionsByPortfolio, portfolio }) =>
    transactionsByPortfolio?.map((transaction) => {
      const investor = investors.find((i) => i.id === transaction.investorId);
      const amount = (portfolio?.totalValue * transaction?.shares) / portfolio?.totalShares;
      return {
        ...transaction,
        login: investor ? investor.login : 'Неизвестный инвестор',
        amount,
        percent: (amount / portfolio?.totalValue) * 100,
      };
    }),
);
// 5490 = 5990
// 5290 = x
export const getTransactionsByUser = createSelector(
  localState,
  ({ investors, transactionsByUser, portfolio }) =>
    transactionsByUser?.map((transaction) => {
      const investor = investors.find((i) => i.id === transaction.investorId);
      const amount = (portfolio?.totalValue * transaction?.shares) / portfolio?.totalShares;
      return {
        ...transaction,
        login: investor ? investor.login : 'Неизвестный инвестор',
        amount,
        percent: (amount / portfolio?.totalValue) * 100,
      };
    }),
);

// Селектор для получения ошибки
export const getError = createSelector(localState, get('error'));

// Селектор для получения сообщения об успешной операции
export const getSuccessMessage = createSelector(localState, get('successMessage'));
