import styled from 'styled-components';

export const Wrapper = styled.span`
  img {
    height: 25px;
    padding-right: 10px;
    cursor: pointer;
  }

    @media (max-width: 1000px) {
        font-size: 18px;
        img {
            height: 35px;
        }
    }
`;
