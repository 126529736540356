import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 15px;

  .ag-header-cell-text {
    font-size: 14px;
  }

    @media (max-width: 1000px) {
        font-size: 18px;
    }
`;
