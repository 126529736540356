import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 8px;
`;

export const InputNumberWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .ant-select {
        width: 200px;
        margin-right: 10px;
        margin-bottom: 0;
        div {
            margin-bottom: 0;
        }
    }

    .ant-input {
        width: 200px;
        margin-right: 10px;
    }
`;

export const CardWrapper = styled.div`
    margin-top: 20px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    h2 {
        margin-bottom: 10px;
    }

    div {
        margin-bottom: 10px;
    }
`;
