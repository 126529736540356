import { all } from 'redux-saga/effects';

import { authSaga } from './auth';
import { notificationSaga } from './notification';
import { refreshSaga } from './refresh';
import { orderSaga } from './order';
import { userSaga } from './user';
import { searchSaga } from './search';
import { investorSaga } from './investor';

function* rootSaga() {
  yield all([
    authSaga(),
    notificationSaga(),
    refreshSaga(),
    orderSaga(),
    userSaga(),
    searchSaga(),
    investorSaga(),
  ]);
}

export default rootSaga;
